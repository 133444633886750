.contact-body {
    display: flex;
    margin: 5rem;
    height: auto;
}

.loc {
    position: relative;
    width: 100%;
}

.loc img {
    display: block;
    height: 100vh;
}

.contact-body-left {
    /* width: 50%; */
    padding-left: 7rem;
}

.contact-body-right {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.body-left-wrap {
    display: flex;
    /* justify-content: center; */
    width: 50%;
}

.contact-left-tagline {
    font-size: 16px;
    font-style: italic;
    color: #c59a8e;
}

.contact-left-text {
    margin-top: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #8a8080;
}

.contact-icon-text {
    color: #847a7a;
    margin-top: 10px;
    font-size: 13px;
}

.input-wrap {
    display: flex;
}

.half-input {
    padding: 0 8px;
    width: 50%;
    min-height: 1px;
}

.contact-inp {
    height: 40px;
    width: 95%;
    margin: 0 0 16px;
    padding: 10px;
    font-size: 16px;
    color: #847a7a;
    background-color: #fcf8f6;
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
}

.contact-inp:focus {
    outline: none;
    outline-width: 0;
}

textarea {
    width: 97%;
    margin: 0 0 16px;
    padding: 16px 10px;
    font-size: 16px;
    color: #847a7a;
    background-color: #fcf8f6;
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
}

textarea:focus {
    outline: none;
    outline-width: 0;
}

.full-input {
    padding: 0 8px;
    width: 100%;
    min-height: 1px;
}

.w-97 {
    width: 97%;
}

@media (max-width: 933px) {

  .contact-body {
    flex-direction: column;
    margin: 1.5rem;
    row-gap: .75rem;
  }

  .body-left-wrap {
    width: 100%;
  }

  .contact-body-left {
    padding-left: .25rem;
  }

  .contact-body-right {
    width: 100%;
  }

  .input-wrap {
    flex-direction: column;
  }

  .half-input {
    width: 100%;
    padding: 0 ;
  }

  .full-input {
    padding: 0 ;
  }
}