.ministry-container {
  display: flex;
  justify-content: center;
  margin: 50px auto;
  gap: 5%;
  width: 88%;
}

.ministry-container 
aside {
  width: 25%;
}

.ministry-container
.ministry-content-cont
{
  width: 78%;
}

.ministry-container p,
.ministry-container li {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 25px;
  font-size: 15px;
}

h2 {
  text-transform: uppercase;
  font-size: 30px;
  margin: 0.1em 0 0;
}

aside * {
  margin: 0 auto;
  width: 100%;
}

aside img {
  border-radius: 50%;
  height: 200px;
  width: 65%;
  display: block;
}

.aside-title-container,
.leader-desc {
  margin-top: 1em;
}

aside h3 {
  text-transform: uppercase;
  font-size: 21px;
  margin-top: 1.2rem;
}

p {
  text-align: justify;
}

aside p {
  text-align: justify;
  color: #8a8080;
}

aside p:first-of-type {
  font-style: italic;
  font-size: 13px;
  color: #c59a8e;
}

.ministry-container
div 
img {
  width: 100%;
}

figure {
  margin: 0;
}

figure 
img {
  margin: 0 0 39px;
  max-height: 650px;
}

section
p {
  margin: 0.5rem 0;
}

.quote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  quotes: none;
  margin: 1rem 0;
}

.quote-container-1 {
  height: 20vh;
  font-family: "Noto Serif", serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  color: #c59a8e;
  padding: 0 3rem;
}

.quote-container-2 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 600;
  font-size: 25px;
  background-color: #29191a;
  color: white;
}

.quote-container-2 q {
  text-align: left;
  padding: 3rem 2.5rem;
}

.two-media-container {
  width: 100% !important;
  display: flex;
  margin: 1.5rem 0;
  gap: 1em;
}

.two-media-container div {
  width: 50%;
  display: inline-block;
}

.two-media-container img {
  height: 280px;
}

/* Men of Purpose page */
/* Might become a utility class (for emphasis) */
.value-title {
  font-weight: 500;
  color: black;
}

@media (max-width: 933px) {
  .ministry-container {
    flex-direction: column;
    margin: 1rem;
    width: inherit;
  }

  .ministry-container aside {
    width: 100%;
    display: flex;
    align-items: center ;
    column-gap: .5rem;
  }

  .ministry-container .ministry-content-cont {
    width: inherit;
    row-gap: .25rem;
  }

  aside {
    display: flex;
    flex-direction: column;
  }

  aside img {
    height: 80px;
    width: 23%;
  }

  aside h3 {
    font-size: 1rem;
  }

  .leader-desc {
    margin-top: 4px;
  } 

  .media-lg-content-container {
    display: grid;

  }

  .media-lg-content-container .media-container {
    width: 100%;
  }

}