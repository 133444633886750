.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;
    padding-bottom: .75rem;
}

h2 {
    margin-bottom: 16px;
}

label {
    margin: .25rem;
}

.admin-input {
    background: #eeeeeb;
    border: none;
    border-radius: .375rem;
    margin: .25rem;
    padding-left: 1rem;
    width: 540px
}

.admin-input:focus {
    background: #eeeeeb;
    border: none;
    border-radius: .375rem;
    margin: .25rem;
    padding-left: 1rem;
    width: 540px
}

.admin-input1 {
    height: 40px;
    width: 540px;
    margin: 0 0 16px;
    padding: 10px;
    font-size: 16px;
    color: #847a7a;
    background-color: #fcf8f6;
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
}

.admin-input1:focus {
    outline-width: 0;
}

.contact-form-button {
    border: none;
    font-size: 16px;
    width: 540px;
    height: 40px;
    padding: 10px;
    margin: .25rem auto;
    background-color: #ffc107;
}

@media (max-width: 933px) {
    .admin-input1 {
        width:80vw;
        height: 32px;
    }

    .contact-form-button {
        width: 88%;
        justify-content: center;
        display: flex;
        margin: 0 auto;
    }
}