.home-hero-wrap {
    max-height: 800px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
}

.home-hero-wrap > img {
    display: block;
    width: 100%;
}

.home-container {
    margin-top: -114px
}

.home-hero-txt {
    position: absolute;
    display: block;
    top: calc(40%); 
    color: white;
    font-size: 84px;
    font-weight: 500;
    letter-spacing: 8px;
}

.three-img-row-sec {
    display: flex;
    flex-direction: row;
    /*  */
    padding: 0 1rem;
    justify-content: center;
}

.three-details-row-sec {
    display: flex;
    flex-direction: column;
    /*  */
    height: 420px;
    border: 1px solid darkgrey; 
}

.img-row-sec-item {
    padding: 40px 20px;
    width: 32%;
}

.home-sec-img-container {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    overflow: hidden;
}

.home-sec-img-container img {
    display: block;
    width: 100%;
}

.home-sec-img-container img:hover {
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    transform: scale(1.02) translateZ(0);
}

.img-row-txt{
    position: absolute;
    display: block;
    top: calc(25%); 
    color: white;
    font-size: 30px;
}

.media-lg-content-container {
    display: flex;
}

.media-lg-content-container div:not(.media-container){
    background-color: rgb(250, 246, 243);
    width: 35%;
}

.media-lg-content-container 
.media-container  {
    width: 60%;
}

.media-lg-content-container 
.media-container img {
    width: 100%;
}

.media-lg-content-container div:nth-of-type(2) {
    padding: 3rem;
}
.service-list-time {
    font-family: "Montserrat", sans-serif;
    line-height: 2;
    list-style:symbols('-');
}

@media (max-width: 933px) {
    
    .home-container{
        margin-top: 0;
    }

    .home-hero-wrap {
        text-align: center;
    }

    .home-hero-txt  {
        font-size: 44px;
    }

    .three-img-row-sec {
        flex-direction: column;
        padding: 8px;
        row-gap: 8px;
    }

    .img-row-sec-item {
        width: 100%;
        padding: 0;
    }

    .page-title {
        font-size: 2rem;
        text-align: center;
    }

    .media-lg-content-container div:not(.media-container) {
        width: 100%;
    }
    
}