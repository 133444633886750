h1, h2, h3, h4, h5 {
  letter-spacing: .07em;
  font-weight: 600;
  color: #29191a;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

p {
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  font-size: 15px;
  color: #514b4b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.btn-style {
  font-family: "Montserrat", sans-serif;
  border: 1px solid #29191a;
  background-color: #29191a;
  color: white;
  line-height: 2em;
  text-transform: uppercase;
  letter-spacing: .15em;
  font-size: 12px;
  font-weight: 600;
  vertical-align: bottom;
}

.btn-sm-style {
  padding: 10px 28px;
}

.btn-lg-style {
  padding: 17px 38px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noto-serif {
  font-family: "Noto Serif", serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.caption-title {
  font-family: "Noto Serif", serif;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  color: #c59a8e;
}

.caption-title-2 {
  font-size: 16px;
}



