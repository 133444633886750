.event-container {
    margin: 2rem 4rem;
    height: 95vh;
}

.search-container {
    margin-bottom: 1.5rem;
    border: 1px solid #ddd;
    width: 100%;
    height: 4.25rem;
    display: flex;
    align-items: center;
}

.search-event {
    height: 30px;
    font-size: 18px;
    border: none;
}

.pad-l {
    padding-left: 1rem;
}

.mar-r {
    padding-right: .5rem;
}

input {
    outline: none;
    flex: 1 0 350px;
}

.search-btn {
    color: white;
    background-color: #334aff;
    font-weight: 600;
    height: 2.5rem;
    width: 120px;
    border: none;
    border-radius: .2rem;
    margin-right: 1rem;
}

.event-view-wrap {
    font-weight: 500;
    padding: 24px 0px;
    margin: 0 12px;
    cursor: pointer;
}

.bd-btm-view-sel {
    border-bottom: 2px solid #220088;

}