a {
  text-decoration: none;
}

.header-container {
  padding: 0.75rem 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: relative;
  z-index: 99;
}

.header-container-non-home {
  background-color: #fbf9f9;
  border: 1px solid #fdf9f6;
}

.header-container-non-home * {
  color: #29191a;
}

.header-container ul {
  padding-left: 0;
  list-style: none;
  text-decoration: none;
}

li a {
  color: white;
}

.main-menu-container,
.btn-subscribe {
  display: flex;
  gap: 32px;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: .15em;
  text-transform: uppercase;
  align-items: center;
}

.btn-subscribe {
  border: 1px solid white;
  color: white;
  gap: 0.6rem;
  background-color: transparent;
  padding: 0.6rem 0.4rem;
}

.btn-subscribe img {
  height: 1.5rem;
}

.img-logo {
  height: 64px;
}

.main-menu-container li {
  cursor: pointer;
}

.main-menu-container li a:hover,
.main-menu-container span:hover {
  color: #c59a8e;
  transition: color .2s ease-out;
}

.main-menu-home * {
  color: white;
}

.main-menu-non-home {
  color: white;
}

.main-menu-container > span {
  padding: .25rem;
}

.submenu-container {
  /* z-index: 99; */
  position: absolute;
  /* width: 150px; */
  width: 271px;
  /* padding: 1rem 0; */
  padding: 35px 0 37px;
  /* removed uppercases*/
  text-transform: none;
  font-style: italic;
  font-family: "Noto Serif", serif;
  background-color: #fdf9f6;
  font-weight: 400;
  font-size: 13px;
}

.submenu-container li {
  margin: 10px 15px;
}

.submenu-container li a {
  color:#655d5d;
}

.submenu-home {
  top:114px;
}

.submenu-non-home {
  top:92px;
  /* visibility: hidden; */
}

.socials-container a img {
  margin: 0.5rem;
  height: 24px;
  width: 24px;
}

.socials-container {
  color: white;
}

.socials-container-non-home {
  filter: brightness(0%);
}

/** Banner **/
.banner-container {
  height: 72vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center
}

.page-title {
  color: white;
  font-size: 4rem;
  letter-spacing: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
}

.mobile-title {
  display: none;
}

.toggle-mb-display {
  display: flex;
}

@media (max-width: 933px) {
    
  .header-container {
      background-color: #847a7a;
      width: 100vw;
      padding: 0;
      justify-content: center;
      flex-direction: column;
  }

  .header-container ul {
    flex-direction: column;
  }

  .socials-container {
    display: none;
  }

  .subscribe-wrap {
    display:none;
  }

  .nav-logo {
    display: none;
  }

  .mobile-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .img-logo {
    margin: .5rem 1rem;
    height: 24px;
    width: 24px;
  }


  .toggle-mb-display {
      display: none;
  }

  .non-home-img-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header-container-non-home * {
    color: white;
  }

  .banner-container{
    height: 52vh;
  }

  .submenu-container {
    position: inherit;
    width: inherit;
    padding: 0;
    text-transform: none;
    font-style: italic;
    background-color: inherit   ;
  }

  .submenu-non-home {
    visibility: visible;
  }

  .submenu-container li a {
    color: white;
  }

  .submenu-home {
    top: inherit;
  }

  .main-menu-container > span {
    padding: 0;
    text-align: center;
  }

  .main-menu-container {
    gap: 14px;
    font-size: 14px;
}

}