.media-four-container {
  display: flex;
  height: 420px;
}

.media-four-container div {
  background-color: black;
  width: 25%
}

.media-four-container div img {
 height: 100%;
 width: 100%;
 opacity: 1;
 display: block;
 transition: .5s ease;
 backface-visibility: hidden;
}

.overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.media-four-container div:hover img {
  opacity: 0.7;
}

.media-four-container div:hover .overlay {
  opacity: 1;
}

.media-with-list-container {
  height: auto;
  /* padding: 4rem; */
  padding: 8rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.left-container {
  text-align: center;
  margin-bottom: 0;
}

.left-container img {
  height: 500px;
  width: 670px;
}

.right-container {
  padding: 0 2rem;
  width: 30%;
}

.right-container section {
  padding: 1rem 0;
}

.right-container img {
  margin-right: 0.5rem;
}

.right-container
h2 {
  text-transform: uppercase;
  font-size: 1.4rem;
  color: #29191a;
}

.media-sm-content-cont {
  display: flex;
  justify-content: space-between;
  padding: 8rem 5rem;
}


.media-sm-content-cont .caption-title {
  margin-bottom: 0.4rem;
}

.media-sm-content-cont h2{
  font-size: 34px; 
}

.media-sm-content-cont div {
  width: 45%;
}

.media-sm-content-cont img {
  width: 100%;
}

.cover-right-text-overlay-container {
  background-image: url("/public/church-page-banner.jpg");
  height: 80vh;
  padding: 3rem;
  display: flex;
  justify-content: end;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}

.cover-right-text-overlay-container
div {
  width: 60%;
  margin: 2rem 2.5rem;
  font-size: 2.5rem;
  font-weight: 500;
  font-style: italic;
  text-align: right;
  color: #ffeadc;
}

.about-page-quote-container p {
  text-align: right;
}

.about-page-quote-container 
p {
  text-transform: uppercase;
  color: white;
  font-style: normal;
  margin-top: 2rem;
}

@media (max-width: 933px) {
  .media-with-list-container {
    flex-direction: column;
    padding: 1rem;
  }

  .media-with-list-container > div {
    width: inherit;
    position: relative;
  }

  .left-container img {
    display: block;
    width: 100%;
  }

  .cover-right-text-overlay-container {
    height: 40vh;
  }

  .cover-right-text-overlay-container div {
    font-size: 1rem;
  }

  .about-page-quote-container p:first-of-type {
    font-size: 10px;
  }
  .about-page-quote-container p:nth-of-type(2) {
    font-size: 8px;
    margin: 4px ;
  }

  .media-sm-content-cont div {
    width: 99%;
  }

  .media-sm-content-cont {
    flex-direction: column;
    padding: 2.5rem;
  }

  .media-sm-content-cont button {
    display: flex;
    margin: 0 auto .75rem;
  }

  .media-four-container {
    flex-direction: column;
    height: auto;
  }

  .media-four-container div{
    width: 100%;
  }
}