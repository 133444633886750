.footer-container {
  background-color: #faf6f3;
  padding: 3rem 0 1.2rem;
}

.footer-main-content {
  display: grid;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.church-info-footer {
  grid-row:1;
  grid-column: 1/6;
  font-weight: 500;
}

.ministries-container {
  grid-row:1;
  grid-column: 7;
}

.involved-container {
  grid-row:1;
  grid-column: 8;
}

.church-info-footer > div {
  padding-left: 1rem;
}

.socials-container {
  padding-left: 0;
}

.logo-footer {
  height: 3.8rem;
}

.church-hrs-cont {
  margin-top: 0;
}

.footer-contact-cont p{
  margin: 0;
}

.nav-footer-title {
  font-size: 1.3rem;
  margin: 0;
}

.footer-list {
  list-style:none;
  line-height: 1.5;
  padding-left: 0;
  font-size: 1.1rem;
  margin-top: 0.6rem;
}

.footer-list > li {
  cursor: pointer;
}

.copyright-text {
  margin: 0;
  text-align: center;
}

@media (max-width: 933px) {

  .footer-main-content {
    display: flex;
    flex-direction: column;
    row-gap: .75rem;
    text-align: center;
  }

  .church-info-footer p {
    text-align: center;
  }
}