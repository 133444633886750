.img-give-sec-item {
    padding: 40px 20px;
    width: 15%;
}

.three-give-row-sec {
    display: flex;
    flex-direction: row;
    /*  */
    padding: 0 1rem;
    justify-content: center;
}

.give-sec-img-container {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
}

.give-sec-img-container img {
    display: block;
    width: 100%;
}
.give-sec-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 3.75rem 0 2rem 0;
}

.give-title-head {
    color: #c59a8e;
    font-style: italic;
    
}

.give-title-sub {
    font-size: 44px;
    font-weight: 600;
    /* line-height: 44px; */
    letter-spacing: .07em;
}

.give-img-details {
    margin: 1rem 0;
}

.give-platform {
    font-size: 12px;
    color: #c59a8e;
    font-style: italic;
}

.give-platform-handle {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: .07em;
}

@media (max-width: 933px) {
    
    .give-title-sub {
        font-size: 1.5rem;
    }

    .give-platform {
        display: none;
    }
    
    .give-platform-handle {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .07em;
    }

    .three-give-row-sec {
        flex-direction: row;
        justify-content: space-around;
    }

    .img-give-sec-item {
        padding: 20px;
        max-width: 30%;
        width: 18%;
    }
}